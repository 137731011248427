<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <h1>Карта сайта</h1>
          <ul class="sm-links">
            <li v-for="(item, i) in links" :key="i" class="sm-link">
              <router-link
                v-if="item.title"
                :to="{ name: item.route, params: item.params }"
                class="sm-link__title"
              >
                {{ item.title }}
              </router-link>
              <ul v-if="item.child_routes && item.child_routes.length" class="sm-link__childs">
                <li v-for="(r, i) in item.child_routes" :key="i" class="sm-links__child">
                  <router-link
                    v-if="r.route"
                    :to="{ name: r.route, params: r.params }"
                    class="sm-links__child-link"
                  >
                    {{ r.title }}
                  </router-link>
                  <a v-else @click.prevent="r.method(r.params)" class="sm-links__child-link" href="#">
                    {{ r.title }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import IdeaSendModal from "@/views/ic/components/modals/IdeaSendModal.vue";
import LoginModal from "components/modals/LoginModal.vue";
import LinksModal from "components/modals/LinksModal.vue";
import SupportModal from "components/modals/SupportModal.vue";

export default {
  name: "SiteMapPage",
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_PAGE");
  },
  data() {
    return {
      links: [
        {
          title: "Главная страница",
          route: "home",
        },
        {
          title: "Новости",
          route: "news",
        },
        {
          title: "Глава города",
          route: "mayor",
          child_routes: [
            {
              title: "Деятельность",
              route: "mayor_activity",
            },
            {
              title: "Биография",
              route: "mayor",
            },
            {
              title: "Медиа",
              route: "mayor_media",
            },
          ],
        },
        {
          title: "Местное самоуправление",
          route: "localStructure",
          child_routes: [
            {
              title: "Структура",
              route: "localStructure",
            },
            {
              title: "Органы власти",
              route: "localGovernments",
            },
            {
              title: "Учреждения и организации",
              route: "localOrgs",
            },
            {
              title: "Деятельность",
              route: "localActivity",
            },
            {
              title: "Проекты",
              route: "projects",
            },
          ],
        },
        {
          title: "Документы",
          route: "documents",
          child_routes: [
            {
              title: "Нормативно-правовые акты",
              route: "documents",
            },
            {
              title: "Заявления и формы запросов",
              route: "documents",
            },
          ],
        },
        {
          title: "Услуги",
          route: "services_catalog",
          child_routes: [
            {
              title: "Каталог услуг",
              route: "services_catalog",
            },
            {
              title: "Центры предоставления услуг",
              route: "service_centers",
            },
            {
              title: "Отчеты и методические материалы ",
              route: "services_reports",
            },
            {
              title: "Вопросы и ответы",
              route: "faq",
            },
            {
              title: "О муниципальных услугах",
              route: "service_about",
            },
          ],
        },
        {
          title: "Мой город",
          route: "mycity",
          child_routes: [
            {
              title: "Общие сведения",
              route: "mycity",
            },
            {
              title: "Исторический очерк",
              route: "mycity_history",
            },
            {
              title: "Афиша",
              route: "posters",
            },
            {
              title: "Медиа",
              route: "media",
            },
            {
              title: "Городские СМИ",
              route: "mycity_smi",
            },
            {
              title: "Справочные данные",
              route: "mycity_reference",
            },
          ],
        },
        {
          title: "Туризм",
          route: "tourism",
          params: {
            link: "routes",
          },
          child_routes: [
            {
              title: "Маршруты",
              route: "tourism",
              params: {
                link: "routes",
              },
            },
            {
              title: "Виртуальные экскурсии",
              route: "tourism",
              params: {
                link: "excursions",
              },
            },
            {
              title: "Размещение",
              route: "tourism",
              params: {
                link: "accommodation",
              },
            },
            {
              title: "Гастрономия",
              route: "tourism",
              params: {
                link: "gastronomy",
              },
            },
            {
              title: "Фотогалерея",
              route: "tourism",
              params: {
                link: "photogallery",
              },
            },
            {
              title: "Памятка туристу",
              route: "tourism",
              params: {
                link: "memo",
              },
            },
          ],
        },
        {
          title: "Карты",
          route: "maps",
          child_routes: [
            {
              title: "Геоинформационный портал",
              url: "https://gis.mkala.ru",
            },
            {
              title: "Галерея карт",
              route: "maps",
            },
          ],
        },
        {
          title: "Сервис общественный контроль",
          route: "sc",
          child_routes: [
            {
              title: "О проекте",
              route: "sc_about",
            },
            {
              title: "Карта",
              route: "sc_map",
            },
            {
              title: "Темы",
              route: "sc_themes",
            },
            {
              title: "Результаты",
              route: "sc_results",
            },
          ],
        },
        {
          title: "Сервис общественые обсуждения",
          route: "pd",
        },
        {
          title: "Сервис идеи горожан",
          route: "ic",
          child_routes: [
            {
              title: "О проекте",
              route: "ic_about",
            },
            {
              title: "Предложить идею",
              method: this.showSendIdeaModal,
            },
            {
              title: "Все инициативы",
              route: "ic_all",
            },
            {
              title: "Реализованные инициативы",
              route: "ic_realized",
            },
          ],
        },
        {
          title: "Обратная связь",
          route: "feedback",
          child_routes: [
            {
              title: "Единое окно обратной связи",
              route: "feedback",
            },
            {
              title: "Направить официальное обращение",
              route: "official_appeal",
            },
            {
              title: "Прием корреспонденции и граждан",
              route: "feedback_reception",
            },
            {
              title: "Единая дежурная диспетчерская служба",
              route: "feedback_dutyservice",
            },
            {
              title: "Контакты",
              route: "feedback",
            },
          ],
        },
        {
          child_routes: [
            {
              title: "Сайты Махачкалы",
              method: this.showLinksModal,
              params: this.$store.state.type.CODE_MKALA_LINKS,
            },
            {
              title: "Сайты Дагестана",
              method: this.showLinksModal,
              params: this.$store.state.type.CODE_DAGESTAN_LINKS,
            },
            {
              title: "Сайты России",
              method: this.showLinksModal,
              params: this.$store.state.type.CODE_RUSSIAN_LINKS,
            },
            {
              title: "Полезные ссылки",
              method: this.showLinksModal,
              params: this.$store.state.type.CODE_USEFUL_LINK,
            },
            {
              title: "Техподдержка",
              method: this.showSupportModal,
            },
            {
              title: "Карта сайта",
              route: "sitemap",
            },
          ],
        },
      ],
    };
  },
  methods: {
    showSendIdeaModal() {
      const token = localStorage.getItem("apollo-token");
      if (token) {
        this.$store.dispatch("GET_USER", token).then(() => {
          this.$store.state._modals.push({
            component: IdeaSendModal,
            options: {
              categories: this.categories,
              user: this.$store.state.user,
            },
          });
        });
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
        });
      }
    },
    showLinksModal(code) {
      this.$store.state._modals.push({
        component: LinksModal,
        options: {
          code: code,
        },
      });
    },
    showSupportModal() {
      this.$store.state._modals.push({
        component: SupportModal,
      });
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
.sm {
  &-link {
    display flex
    flex-direction column
    gap var(--margin)
    padding 24px 0
    border-top 1px solid var(--color_gray_divider)

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &__title {
      font-weight: 600;
      font-size: 1.25em
      line-height: 24px;
    }

    &__childs {
      display flex
      flex-flow row wrap
      gap var(--margin) 40px
    }
  }
}
</style>
